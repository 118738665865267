<template>
  <validation-observer
    ref="criteriaSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="criteriaSaveModal"
      ref="criteriaSaveModalRef"
      body-class="position-static"
      size="lg"
      centered
      :title="isUpdated ? 'Cập nhật tiêu chí' : 'Thêm tiêu chí'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <div class="small-title">
              Chỉ nhập 1 tiêu chí trong 1 lần thêm mới. Những tiêu chí tương đương được tách thành 1 tiêu chí khác.
            </div>
            <b-col cols="12">
              <hr>
            </b-col>
            <div class="title">
              Tiêu chí ưu tiên tuyển sinh:
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="criteriaId">
              <template v-slot:label>
                Tiêu chí phụ thuộc
              </template>
                <v-select
                  v-model="targetItem.criteriaId"
                  :options="dataCriteriaList"
                  :reduce="option => option.value"
                />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên tiêu chí"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Tên tiêu chí"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-for="code"
            >
              <template v-slot:label>
                Mã tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã tiêu chí"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã tiêu chí"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="priorityScore">
              <template v-slot:label>
                Thứ tự ưu tiên <span class="text-danger"> * (Các tiêu chí có thể có cùng thứ tự ưu tiên)</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thứ tự ưu tiên"
                rules="required"
              >
                <b-form-input
                  id="orderNo"
                  v-model="targetItem.priorityScore"
                  type="number"
                  name="priorityScore"
                  placeholder="Thứ tự ưu tiên"
                  :state="getElementState(errors)"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div style="color: #7367f0">
              1. Đánh giá hạnh kiểm:
            </div>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Năm xét hạnh kiểm <span style="color: red">
                1: Lớp 12; 2: Lớp 12 & 11; 3: Lớp 12 & 11 & 10
              </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số năm xét hạnh kiểm"
              >
                <b-form-input
                  id="gradeConduct"
                  v-model="targetItem.gradeConduct"
                  name="gradeConduct"
                  type="number"
                  placeholder="Số năm xét hạnh kiểm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Loại hạnh kiểm tối thiểu cần đạt
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại hạnh kiểm tối thiểu cần đạt"
              >
                <v-select
                  v-model="targetItem.factAdmissionConductId"
                  :options="admissionConducts"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div style="color: #7367f0">
              2. Đánh giá học lực:
            </div>
          </b-col>
          <b-col
            cols="8"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Năm xét học lực <span style="color: red">
                1: Lớp 12; 2: Lớp 12 & 11; 3: Lớp 12 & 11 & 10
              </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số năm xét học lực"
              >
                <b-form-input
                  id="gradeAcademicPerformance"
                  v-model="targetItem.gradeAcademicPerformance"
                  name="gradeAcademicPerformance"
                  type="number"
                  placeholder="Số năm xét học lực"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Loại học lực tối thiểu cần đạt
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại học lực tối thiểu cần đạt"
              >
                <v-select
                  v-model="targetItem.factAcademicPerformanceId"
                  :options="academicPerformances"
                  :reduce="option => option.value"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div style="color: #7367f0">
              3. Kết quả học tập:
            </div>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="admissionScoreTypeId">
              <template v-slot:label>
                Loại điểm
              </template>
                <v-select
                  v-model="targetItem.admissionScoreTypeId"
                  :options="dataAdmissionScores"
                  :reduce="option => option.value"
                />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <template v-slot:label>
                Môn học cụ thể cần xét điểm
              </template>
              <validation-provider
                #default="{ errors }"
                name="Môn học cụ thể cần xét điểm"
              >
                <v-select
                  v-model="targetItem.admissionSubjectId"
                  :options="dataListSubject"
                  :reduce="option => option.value"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="minScore">
              <template v-slot:label>
                Điểm tối thiểu cần đạt
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm tối thiểu cần đạt"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.minScore"
                  name="code"
                  type="number"
                  placeholder="Điểm tối thiểu cần đạt"
                  :state="getElementState(errors)"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div style="color: #7367f0">
              4. Đánh giá khác:
            </div>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group label-for="languageCertificate">
              <template v-slot:label>
                Chứng chỉ ngoại ngữ <span class="text-danger">*</span>
                <i
                  class="fa-solid fa-circle-info ml-1 position-absolute"
                  @click="languageCertificateClick"
                ></i>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chứng chỉ ngoại ngữ"
                rules="required"
              >
                <v-select
                  v-model="targetItem.languageCertificate"
                  :options="addPriorityScoreOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group label-for="confirmationCertificate">
              <template v-slot:label>
                Đối tượng ưu tiên <span class="text-danger">*</span>
                <i
                  class="fa-solid fa-circle-info ml-1 position-absolute"
                  @click="confirmationCertificateClick"
                ></i>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đối tượng ưu tiên"
                rules="required"
              >
                <v-select
                  v-model="targetItem.confirmationCertificate"
                  :options="addPriorityScoreOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Loại cuộc thi, kỳ thi
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại cuộc thi, kỳ thi"
              >
                <v-select
                  v-model="targetItem.scopeCertificate"
                  :options="scopeCertificateOption"
                  :reduce="option => option.value"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Hạng giải tối thiểu
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hạng giải tối thiểu"
              >
                <v-select
                  v-model="targetItem.rankCertificate"
                  :options="rankCertificateTypeOptions"
                  :reduce="option => option.value"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-modal
        ref="modalLanguageCertificate"
        title="Thông tin chứng chỉ ngoại ngữ được chấp nhận"
        size="lg"
        body-class="position-static"
        no-close-on-backdrop
        hide-footer
      >
        <DetailCertificateLanguage
          :criteria-set-id="criteriaSetId"
        />
      </b-modal>
      <b-modal
        ref="modalConfirmation"
        title="Thông tin đối tượng được chấp nhận"
        size="l"
        body-class="position-static"
        no-close-on-backdrop
        hide-footer
      >
        <DetailConfirmation
          :criteria-set-id="criteriaSetId"
        />
      </b-modal>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="!isUpdated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('criteriaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ELIGIBLE_STATUSES, SCOPE_CERTIFICATES, STATUSES } from '@/const/status'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Flag } from '@/const/flag'
import DetailConfirmation from '@/views/admission/criteria/DetaiAssessConfirmation.vue'
import DetailCertificateLanguage from '@/views/admission/criteria/DetaiAssessLanguageCertificate.vue'

export default {
  name: 'CriteriaSave',
  directives: {
    Ripple,
  },
  components: {
    DetailCertificateLanguage,
    DetailConfirmation,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BModal,
    BOverlay,
    vSelect,
    BFormInvalidFeedback,
  },
  props: {
    criteriaSetId: {
      type: Number,
      default: null,
    },
    criteria: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        name: '',
        code: null,
        criteriaSetId: null,
        scopeCertificate: null,
        rankCertificate: null,
        admissionSubjectId: null,
        minScore: null,
        factAcademicPerformanceId: null,
        gradeAcademicPerformance: null,
        factAdmissionConductId: null,
        gradeConduct: null,
        languageCertificate: null,
        confirmationCertificate: null,
        priorityScore: null,
        admissionScoreTypeId: null,
        criteriaId: null,
        status: Flag.ACTIVE,
        orderNo: null,
      },
      dataCriteriaList: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      admissionConducts: 'factAdmissionConduct/admissionConducts',
      academicPerformances: 'factAcademicPerformance/academicPerformances',
      rankCertificateTypes: 'admissionPriorityScore/rankCertificateTypes',
      dataListConfirmationType: 'factAdmissionConfirmationType/dataListConfirmationType',
      dataListSubject: 'factAdmissionSubject/dataListSubject',
      dataAdmissionScores: 'factAdmissionScoreType/admissionScoreTypes',
      criteriaList: 'criteria/criteria',
    }),
    statusOptions() {
      return STATUSES
    },
    addPriorityScoreOptions() {
      return ELIGIBLE_STATUSES
    },
    scopeCertificateOption() {
      return SCOPE_CERTIFICATES
    },
    isUpdated() {
      return !!this.criteria
    },
    rankCertificateTypeOptions() {
      return this.rankCertificateTypes
    },
  },
  methods: {
    ...mapActions({
      createCriteria: 'criteria/createCriteria',
      updateCriteria: 'criteria/updateCriteria',
      getListSubject: 'factAdmissionSubject/getListSubject',
      getAdmissionConducts: 'factAdmissionConduct/getAdmissionConducts',
      getAcademicPerformances: 'factAcademicPerformance/getAcademicPerformances',
      getDataConfirmationType: 'factAdmissionConfirmationType/getConfirmationType',
      getAllAdmissionScores: 'factAdmissionScoreType/getAdmissionScoreTypes',
      getCriteria: 'criteria/getCriteria'
    }),
    async onShow() {
      if ( this.criteria ) {
        this.targetItem = { ...this.criteria }
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getListSubject(),
          this.getAdmissionConducts(),
          this.getAcademicPerformances(),
          this.getDataConfirmationType(),
          this.getAllAdmissionScores(),
          this.getCriteria({ criteriaSetId: this.criteriaSetId })
        ])
        this.dataCriteriaList = this.criteriaList.map(criteria => {
          return {
            value: criteria.id,
            label: criteria.name,
          };
        });
      } catch ( e ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .criteriaSaveFormRef
        .reset()
      this.targetItem = {
        id: null,
        name: '',
        code: null,
        criteriaSetId: null,
        scopeCertificate: null,
        rankCertificate: null,
        admissionSubjectId: null,
        minScore: null,
        factAcademicPerformanceId: null,
        gradeAcademicPerformance: null,
        factAdmissionConductId: null,
        gradeConduct: null,
        languageCertificate: null,
        confirmationCertificate: null,
        priorityScore: null,
        admissionScoreTypeId: null,
        criteriaId: null,
        status: Flag.ACTIVE,
        orderNo: null,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    languageCertificateClick() {
      this.$refs.modalLanguageCertificate.show();
    },
    confirmationCertificateClick(){
      this.$refs.modalConfirmation.show();
    },
    async onSave(type = null) {
      const valid = this.$refs
        .criteriaSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isUpdated
            ? await this.updateCriteria({
              id: this.targetItem.id,
              criteriaSetId: this.criteriaSetId,
              name: this.targetItem.name,
              code: this.targetItem.code,
              admissionSubjectId: this.targetItem.admissionSubjectId,
              scopeCertificate: this.targetItem.scopeCertificate,
              minScore: this.targetItem.minScore,
              factAcademicPerformanceId: this.targetItem.factAcademicPerformanceId,
              gradeAcademicPerformance: this.targetItem.gradeAcademicPerformance,
              factAdmissionConductId: this.targetItem.factAdmissionConductId,
              gradeConduct: this.targetItem.gradeConduct,
              rankCertificate: this.targetItem.rankCertificate,
              languageCertificate: this.targetItem.languageCertificate,
              confirmationCertificate: this.targetItem.confirmationCertificate,
              priorityScore: this.targetItem.priorityScore,
              admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
              criteriaId: this.targetItem.criteriaId,
              status: this.targetItem.status,
              orderNo: this.targetItem.orderNo,
            })
            : await this.createCriteria({
              criteriaSetId: this.criteriaSetId,
              name: this.targetItem.name,
              code: this.targetItem.code,
              admissionSubjectId: this.targetItem.admissionSubjectId,
              scopeCertificate: this.targetItem.scopeCertificate,
              minScore: this.targetItem.minScore,
              factAcademicPerformanceId: this.targetItem.factAcademicPerformanceId,
              gradeAcademicPerformance: this.targetItem.gradeAcademicPerformance,
              factAdmissionConductId: this.targetItem.factAdmissionConductId,
              gradeConduct: this.targetItem.gradeConduct,
              rankCertificate: this.targetItem.rankCertificate,
              languageCertificate: this.targetItem.languageCertificate,
              confirmationCertificate: this.targetItem.confirmationCertificate,
              priorityScore: this.targetItem.priorityScore,
              admissionScoreTypeId: this.targetItem.admissionScoreTypeId,
              criteriaId: this.targetItem.criteriaId,
              status: this.targetItem.status,
              orderNo: this.targetItem.orderNo,
            })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('criteriaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.criteria-select {
  ::v-deep .vs__selected {
    color: white;
    background-color: #7367f0;
  }
}
.small-title {
  color: red
}
.title {
  color: red;
  font-weight: bold;
}
.formula-container {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #333;
  padding: 7px;
  background-color: #f9f9f9;
}

</style>
